import React, {Fragment, useEffect, useState} from 'react';
import { useAuth } from '../../../hooks/useAuth';
import {RiEditLine} from "react-icons/ri";
import CategoryService from "../../../services/CategoryService";
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Circle, Polygon, useMapEvents } from 'react-leaflet';
import {Icon} from 'leaflet';
import iconRoute from '../../../assets/img/marker-icon.png'
import {FaRegTrashAlt} from "react-icons/fa";

const CategoryGeoLocation = ({ tag, category, onSubmit }) => {
    const { user } = useAuth();
    const [drawingMode, setDrawingMode] = useState('MARK'); // 'marker', 'circle', 'polygon'
    const { id, bookingObjectSeasonProperties, ...restFullState } = category;

    const [highlightCoordinates, setHighlightCoordinates] = useState(null);
    const [currentZoom, setCurrentZoom] = useState(14);
    const [selectedMark] = useState(0);
    const [shapeRadius, setShapeRadius] = useState(50);
    const [shapes, setShapes] = useState({MARK: [], CIRCLE: [], POLYGON: []});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fullStateEdit, setFullStateEdit] = useState({
        ...restFullState,
        [tag]: category[tag].map(location => ({
            coordinates: location ? location.coordinates.map((coord) => ({ ...coord})).sort((a, b) => a.position - b.position) || [] : [],
            type: location ? location.type || '' : '',
            radius: location ? location.radius || '' : ''
        }))
    });

    useEffect(() => {
        const { id, bookingObjectSeasonProperties, ...restFullState } = category;

        resetShapes();

        setFullStateEdit({
            ...restFullState,
            [tag]: restFullState[tag].map(location => ({
                coordinates: location ? location.coordinates.map((coord) => ({ ...coord})).sort((a, b) => a.position - b.position) || [] : [],
                type: location ? location.type || '' : '',
                radius: location ? location.radius || '' : ''
            }))
        });
    }, [category]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        resetShapes()
    }, [isModalOpen, fullStateEdit]);// eslint-disable-line react-hooks/exhaustive-deps

    const resetShapes = () => {
        let preSelectDrawMode = 'MARK';
        const cache = {MARK: [], CIRCLE: [], POLYGON: []};

        for(let ii = 0; ii < restFullState[tag].length; ii++) {
            const location = restFullState[tag][ii];
            const coordinates = location ? location.coordinates.map((coord) => ({ ...coord})).sort((a, b) => a.position - b.position) || [] : [];
            cache[location.type].push({
                coordinates,
                type: location.type,
                ...(location.type === 'CIRCLE' ? {radius: location.radius}: {})
            });
            preSelectDrawMode = location.type;
        }

        setDrawingMode(preSelectDrawMode);
        setShapes(cache);
    }

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const submitModal = async () => {
        setIsModalOpen(false);
        await saveChanges();
    };

    const saveChanges = async () => {
        try {
            const update = {
                ...fullStateEdit,
                [tag]: shapes[drawingMode]
            }
            await CategoryService.putCategory(category.id, user, update);
            onSubmit();
        } catch (err) {
            throw err;
        }
    };

    const createMapMarkIcon = () => {
        return new Icon ({
            iconUrl : iconRoute,
            iconAnchor : [12, 41]
        })
    }

    const removePolygonPoint = (position) => {
        const updatedShapes = {...shapes}

        if(position === null) {
            updatedShapes[drawingMode].splice(selectedMark, 1);
        } else {
            updatedShapes[drawingMode][selectedMark].coordinates.splice(position, 1);
            updatedShapes[drawingMode][selectedMark].coordinates = updatedShapes[drawingMode][selectedMark].coordinates.map((item, index) => {
                item.position = index;
                return item;
            })
        }

        setShapes(updatedShapes);
    }

    const getTypeName = (type) =>{
        switch (type) {
            case 'MARK':
                return 'Marker'
            case 'CIRCLE':
                return 'Kreis'
            case 'POLYGON':
                return 'Polygon'
            default:
                return 'UNKNOWN'
        }
    }

    const changeRadius = (e) => {
        let value = parseInt(e.target.value);

        if(value > 10000) {
            value = 9999;
        } else if(value < 1) {
            value = 1
        }

        const updatedShapes = {...shapes};
        setShapeRadius(value);

        if(!!updatedShapes[drawingMode][selectedMark]) {
            updatedShapes[drawingMode][selectedMark].radius = value;
        }

        setShapes({...updatedShapes});
    }
    
    const setPolygonPointHover = (coordinates) => {
        if(drawingMode === 'POLYGON') {
            setHighlightCoordinates(coordinates)
        }

    }

    const MapClickHandler = () => {
        useMapEvents({
            zoomend: (e) => {
                setCurrentZoom(e.sourceTarget._zoom);
            },
            click(e) {
                const { lat, lng } = e.latlng;
                const updatedShapes = {...shapes}
                setShapes({});

                if (drawingMode === 'POLYGON') {
                    if(!updatedShapes[drawingMode][selectedMark]) {
                        updatedShapes[drawingMode].push({coordinates:[], type: drawingMode})
                    }

                    const length = updatedShapes[drawingMode][selectedMark].coordinates.length;
                    updatedShapes[drawingMode][selectedMark].coordinates.push({ lat, lng, position: length })
                } else {
                    updatedShapes[drawingMode] = [{
                        coordinates: [{ lat, lng, position: 0 }],
                        type: drawingMode,
                        ...(drawingMode === 'CIRCLE' ? {radius: shapeRadius}: {})
                    }];
                }

                setShapes(updatedShapes);
            }
        });
        return null;
    };

    return (
        <div className="row" style={{zIndex: "1050"}}>
            <div className="col-9">
                <div className="olap-description-text d-flex flex-wrap gap-2">
                    {fullStateEdit[tag] && fullStateEdit[tag].map((location, index) => (
                        <span className={'badge bg-success-subtle text-success badge-border'} key={index}>
                           Typ:{location.type}, Lat: {location.coordinates[0].lat}, Lon: {location.coordinates[0].lng}, {location.radius ? location.radius : ''}
                        </span>
                        )
                    )}
                </div>
            </div>
            <div className="col-3">
                <div onClick={openModal} className="btn-olap-modal btn-info btn btn-olap-success btn-success btn-lg p-2" data-bs-toggle="offcanvas" data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas">
                    <RiEditLine />
                </div>
                <div id="bottom-rightModal" tabIndex="-1" aria-hidden="true"  className={`modal fade ${isModalOpen ? 'show' : ''}`}
                     style={{ display: isModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}>
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className='modal-header'>
                                <h5 className='modal-title'>
                                    GPS Daten bearbeiten
                                </h5>
                                <button type='button' className='btn-close' aria-label='Close' onClick={closeModal} />
                            </div>
                            <div className='modal-body'>
                                <div className="row">
                                    <div className="col-9">
                                        {
                                            isModalOpen && (
                                                <MapContainer center={[54.31239261756474, 13.088577130518907]} zoom={14} style={{ height: "500px", width: "100%" }}>
                                                    <TileLayer
                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                    />
                                                    <MapClickHandler />
                                                    {!!highlightCoordinates && (<Circle color={'#0ab39c'} center={highlightCoordinates} radius={(19 - currentZoom)*15} />)}
                                                    {shapes[drawingMode].map((marker, idx) => (
                                                        marker.type === 'MARK' ? (
                                                            <Marker
                                                                key={idx}
                                                                position={marker.coordinates[0]}
                                                                icon={createMapMarkIcon()}
                                                            />
                                                        ) : (
                                                            //if market.type === 'circle' then show circle
                                                            marker.type === 'CIRCLE' ? (
                                                                <Circle key={idx} center={marker.coordinates[0]} radius={!!marker.radius ? marker.radius : 1 } />
                                                            ) : (
                                                                <Polygon key={idx + Math.floor(Math.random() * 99999)} positions={marker.coordinates} />
                                                            )
                                                        )
                                                    ))}
                                                </MapContainer>
                                            )
                                        }
                                    </div>
                                    <div className="col-3">
                                        <div className="btn-group">
                                            <button className={`btn ${drawingMode === 'MARK' ? 'btn-primary' : 'btn-soft-primary'}`} onClick={() => setDrawingMode('MARK')}>Marker</button>
                                            <button className={`btn ${drawingMode === 'CIRCLE' ? 'btn-primary' : 'btn-soft-primary'}`} onClick={() => setDrawingMode('CIRCLE')}>Kreis</button>
                                            <button className={`btn ${drawingMode === 'POLYGON' ? 'btn-primary' : 'btn-soft-primary'}`} onClick={() => setDrawingMode('POLYGON')}>Polygon</button>
                                        </div>
                                                <div className={'row mb-3 mt-3'}>
                                                    <div className={'col-4'}>Typ:</div>
                                                    <div className={'col-8'}>{getTypeName(drawingMode)}</div>
                                                    {drawingMode === 'CIRCLE' && (
                                                        <Fragment>
                                                            <div className={'col-4 mt-1 pt-1'}>Radius:</div>
                                                            <div className={'col-8 mt-1'}>
                                                                <input
                                                                    onChange={changeRadius}
                                                                    onBlur={(e) => { if(!e.target.value) { setShapeRadius(1) } }}
                                                                    className={'form-control form-control-sm w-xs shadow-none'}
                                                                    type={'number'}
                                                                    value={shapeRadius} min={0} max={1000}
                                                                />
                                                            </div>
                                                        </Fragment>
                                                    )}
                                                </div>
                                                {(!shapes[drawingMode][selectedMark] || !shapes[drawingMode][selectedMark].coordinates.length) && (
                                                    <div class="alert alert-primary" role="alert">
                                                        Keine Koordinaten vorhanden.
                                                    </div>
                                                )}
                                                {!!shapes[drawingMode][selectedMark] && !!shapes[drawingMode][selectedMark].coordinates.length && (
                                                    <div className={'table-responsive'}>
                                                        <table className={'table table-sm align-middle table-nowrap mb-0 no-hover-effect'}>
                                                            <thead>
                                                            <tr>
                                                                <th className={'pt-2 pb-1 ps-1 pe-0 no-min-width'} scope="col" colSpan={1}>Koordinaten</th>
                                                                <th className={'pt-2 pb-1 ps-0 pe-1 no-min-width text-end'} scope="col" colSpan={2}>
                                                                    {!!shapes[drawingMode][selectedMark] && !!shapes[drawingMode][selectedMark].coordinates.length && (
                                                                        <button className={'btn-info btn btn-danger btn-sm pt-0 pb-0'} onClick={() => { removePolygonPoint(null); }}>
                                                                            Alle löschen
                                                                        </button>
                                                                    )}
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {shapes[drawingMode][selectedMark].coordinates.map((cord, index) => (
                                                                <tr
                                                                    key={`coordinates-row-${index}`}
                                                                    onMouseOver={() => { setPolygonPointHover(cord) }}
                                                                    onMouseOut={() => { setPolygonPointHover(null) }}
                                                                >
                                                                    <td className={'pt-1 pb-1 no-min-width ps-1 pe-0'}>
                                                                        Latitude
                                                                        <br/>
                                                                        Longitude
                                                                    </td>
                                                                    <td className={'pt-1 pb-1 ps-1 pe-1'}>
                                                                        {cord.lat}
                                                                        <br/>
                                                                        {cord.lng}
                                                                    </td>
                                                                    <td className={'no-min-width pt-1 ps-0 pb-1 pe-1'}>
                                                                        {drawingMode === 'POLYGON' && (
                                                                            <FaRegTrashAlt
                                                                                onClick={() => { removePolygonPoint(index); }}
                                                                                className={'delete-edit-geo-coordinates-item'}
                                                                            />
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                    </div>
                                </div>
                            </div>
                            <div className='modal-footer'>
                            <span className='btn btn-light' onClick={closeModal}>
                                Abbrechen
                            </span>
                                <span className='btn btn-success' onClick={submitModal}>
                                Speichern
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryGeoLocation;